import { isNil } from 'lodash'
import { Advisor } from 'types/graphql'

const advisorAppendRegex = /-\d+$/

export const removeAdvisorSuffix = (
  identifier: string | undefined,
): string | undefined => identifier?.replace(advisorAppendRegex, '')

export const splitProviderAndAdvisor = (
  identifier?: string | undefined,
): { provider: string; advisorId: string | undefined } => {
  const provider = removeAdvisorSuffix(identifier) ?? ''
  const advisorId = identifier?.replace(provider, '').replace(/-/, '')

  return {
    provider,
    advisorId,
  }
}

/**
 * @param identifier
 * @param advisor
 * @returns
 */
export const appendAdvisor = (
  identifier: string,
  advisor?: Advisor,
): string => {
  const id = identifier.replace(advisorAppendRegex, '')

  if (isNil(advisor)) {
    return id
  }

  return `${id}-${advisor.id}`
}
