import { useEffect } from 'react'

export const useCatchResizeObserverError = (): void => {
  useEffect(() => {
    const callback = (e: any) => {
      if (e.message?.includes?.('ResizeObserver')) {
        e.stopImmediatePropagation()
        e.preventDefault()

        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }

    window.addEventListener('error', callback)
    return () => {
      window.removeEventListener('error', callback)
    }
  }, [])
}
