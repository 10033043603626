import { EditorState, replaceNodeAtPosition, Transaction } from 'remirror'
import {
  AvailableTopics,
  MergeFieldArea,
  MergeFieldContentToReplace,
  MergeFieldToReplace,
  NotesGroup,
  NoteTag,
} from '../NoteReplacer.interfaces'
import {
  getDeepListMergeFields,
  getTopicsFromMergeField,
} from '../NoteReplacerList.helpers'

import { AREA_TYPES, AreaTypes } from '../NoteReplacer.constants'
import { processMergeFieldToReplace as processMergeFieldToReplaceBulletList } from './displayHandlers/NoteReplacerBulletListDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceCheckList } from './displayHandlers/NoteReplacerCheckListDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceInline } from './displayHandlers/NoteReplacerInlineDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceOrderedList } from './displayHandlers/NoteReplacerOrderedListDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceParagraph } from './displayHandlers/NoteReplacerParagraphDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplacePlaceholder } from './displayHandlers/NoteReplacerPlaceholderDisplay.handler'

import { TagTypes } from 'constants/tags'
import { SmartFieldKind } from 'stores/smartfields/constants'
import { validate as uuidValidate } from 'uuid'
import { removeSid } from '../../../../../stores/smartfields/utils'
import {
  DISPLAY_MODE,
  DisplayMode,
} from '../../smartFieldAtom/utils/displayMode.constant'
import {
  ifNodeEmptyRemove,
  removeEmptyNode,
} from './utils/removeEmptyNode.util'

/**
 * LEGACY! FROALA
 * When migrating from froala the modes will be empty so we must infer depending on the context. Eg: in a numbered list the default handler would be Numbered list.
 * @deprecated
 */
function getDefaultHandler(areaType: AreaTypes): DisplayMode {
  switch (areaType) {
    case AREA_TYPES.BULLET_LIST:
      return DISPLAY_MODE.ULLIST
    case AREA_TYPES.ORDERED_LIST:
      return DISPLAY_MODE.OLLIST
    case AREA_TYPES.TASK_LIST:
      return DISPLAY_MODE.CHECKLIST
    default:
      return DISPLAY_MODE.PARAGRAPH
  }
}

export function replaceListMentionAtoms(
  tr: Transaction,
  state: Readonly<EditorState>,
  placeholderArea: MergeFieldArea,
  notes: NotesGroup[],
  subtopics?: NoteTag[],
): void {
  // FIXME: this is re-getting list nodes. move logic to the same place
  const mergeFields = getDeepListMergeFields(
    placeholderArea.node,
    placeholderArea.pos,
    tr,
    subtopics,
  )
  const groupedNotes = prepareNotesForList({
    state,
    mergeFieldsToReplace: mergeFields,
    notes,
    defaultHandler: getDefaultHandler(placeholderArea.areaType),
  })

  if (groupedNotes) {
    const orderedNotes = groupedNotes.reverse()
    orderedNotes.forEach((mergeFieldContent) => {
      if (mergeFieldContent.content.length) {
        replaceNodeAtPosition({
          pos: mergeFieldContent.pos,
          tr,
          content: mergeFieldContent.content,
        })
      } else {
        if (uuidValidate(removeSid(mergeFieldContent.type))) {
          return
        }
        removeEmptyNode({ pos: mergeFieldContent.pos, tr })
      }
    })
    ifNodeEmptyRemove({ pos: placeholderArea.pos, tr })
  } else {
    removeEmptyNode({ pos: placeholderArea.pos, tr })
  }
}

function prepareNotesForList({
  state,
  mergeFieldsToReplace,
  notes,
  defaultHandler,
}: {
  state: Readonly<EditorState>
  mergeFieldsToReplace: MergeFieldToReplace[]
  notes: NotesGroup[]
  /**
   * LEGACY! FROALA
   * @deprecated
   */
  defaultHandler: DisplayMode
}): MergeFieldContentToReplace[] {
  const placeholderTopics = mergeFieldsToReplace.map(
    ({ mergeFieldType }) => mergeFieldType,
  )
  const availableTopics = mergeFieldsToReplace
    .map(getTopicsFromMergeField)
    .filter((t): t is AvailableTopics => t !== null)

  const filteredNotesGroup = notes.filter((noteGroup) => {
    const mergeFieldTopic = availableTopics.find(
      (topic) => topic.mergeFieldType === noteGroup.type,
    )
    if (mergeFieldTopic) {
      const availableSubtopics = getAvailableSubtopics(
        availableTopics,
        noteGroup,
      )
      if (mergeFieldTopic.tags && mergeFieldTopic.tags.length) {
        return noteGroup.notes.some((note) => {
          const noteSubtopic = note.tags.find((tag) =>
            availableSubtopics.includes(tag),
          )
          return Boolean(noteSubtopic)
        })
      }
      return true
    }
    return false
  })

  /* Order merge fields in the order they appear in the template */
  const orderedNotesGroup = filteredNotesGroup.sort(
    (a, b) =>
      placeholderTopics.indexOf(a.type) - placeholderTopics.indexOf(b.type),
  )

  return createOrderedListAreaWrapper({
    state,
    items: mergeFieldsToReplace,
    notesGroup: orderedNotesGroup,
    defaultHandler,
  })
}

function createOrderedListAreaWrapper({
  state,
  items,
  notesGroup,
  defaultHandler,
}: {
  state: Readonly<EditorState>
  items: MergeFieldToReplace[]
  notesGroup: NotesGroup[]
  /**
   * LEGACY! FROALA
   * @deprecated
   */
  defaultHandler: DisplayMode
}): MergeFieldContentToReplace[] {
  const elements = items.map((item) =>
    processMergeFieldToReplaceByDisplayMode({
      state,
      mergeFieldToReplace: item,
      content: notesGroup,
      defaultHandler,
    }),
  )

  return elements
}

function processMergeFieldToReplaceByDisplayMode({
  state,
  mergeFieldToReplace,
  content,
  defaultHandler = DISPLAY_MODE.CHECKLIST,
}: {
  state: Readonly<EditorState>
  mergeFieldToReplace: MergeFieldToReplace
  content: NotesGroup[]
  /**
   * LEGACY! FROALA
   * @deprecated
   */
  defaultHandler: DisplayMode
}): MergeFieldContentToReplace {
  const displayHandlers = {
    [DISPLAY_MODE.CHECKLIST]: processMergeFieldToReplaceCheckList,
    [DISPLAY_MODE.INLINE]: processMergeFieldToReplaceInline,
    [DISPLAY_MODE.OLLIST]: processMergeFieldToReplaceOrderedList,
    [DISPLAY_MODE.PARAGRAPH]: processMergeFieldToReplaceParagraph,
    [DISPLAY_MODE.ULLIST]: processMergeFieldToReplaceBulletList,
  }

  if (
    [SmartFieldKind.PLACERHOLDER, SmartFieldKind.CONTACT].includes(
      mergeFieldToReplace.kind as 'placeholder' | 'contact',
    )
  ) {
    return processMergeFieldToReplacePlaceholder(
      state,
      mergeFieldToReplace,
      content,
    )
  }

  const displayHandler = mergeFieldToReplace.displayMode
    ? displayHandlers[mergeFieldToReplace.displayMode]
    : displayHandlers[defaultHandler]

  return displayHandler(state, mergeFieldToReplace, content)
}

/**
 * Gets list of available subtopics for a particular note group.
 *
 * For example, if we have the following set of notes:
 * - Financial Planning
 * - Financial Planning::Retirement
 * - Financial Planning::Client to-do
 *
 * It will return a list of the tags values, like:
 * `['retirement', 'client_to_do']`
 *
 * @param availableTopics
 * @param noteGroup
 * @returns List of available subtopics values.
 */
function getAvailableSubtopics(
  availableTopics: AvailableTopics[],
  noteGroup: NotesGroup,
): string[] {
  return availableTopics
    .filter((topic) => topic.mergeFieldType === noteGroup.type)
    .map((topic) => topic.tags)
    .flat()
    .map((tag) => {
      if (typeof tag === 'string') {
        return tag
      } else if (tag.type === TagTypes.SUBTOPIC) {
        return tag.value
      } else {
        return ''
      }
    })
    .filter((tag) => tag !== '')
}
