import styled from 'styled-components'

const Container = styled.div`
  padding-bottom: 50px;
  .container {
    max-width: 600px;
    padding: 25px;
    margin-top: 25px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    line-height: 1.8;
  }
  .logo {
    margin-bottom: 25px;
  }
  .logo img {
    max-width: 200px;
  }
  h1 {
    font-size: 28px;
    color: #28a745;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    line-height: 1.8;
    margin: 15px 0;
  }
  .email {
    margin: 25px 0;
    font-weight: bold;
    color: #28a745;
    font-size: 18px;
  }
  .guidance {
    margin-top: 30px;
    text-align: left;
  }
  .guidance h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .guidance ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  .guidance li {
    margin-bottom: 15px;
    font-size: 18px;
  }
  footer {
    margin-top: 30px;
    font-size: 16px;
    color: #666;
  }
`

const Maintenance = () => {
  return (
    <Container>
      <div className="container">
        <h1>We'll Be Back Soon!</h1>
        <p>
          Pulse360 is undergoing scheduled maintenance to update our database.
          We expect this to take place from{' '}
          <strong>5:00 AM ET to 8:00 AM ET</strong>.
        </p>
        <p>
          We will notify all users when we are back online. Thank you for your
          patience!
        </p>
        <p className="email">
          If you have any urgent concerns, please email us at:{' '}
          <a href="mailto:support@pulse360.com">support@pulse360.com</a>
        </p>

        <div className="guidance">
          <h2>How to Record Your Meetings During Maintenance</h2>
          <ul>
            <li>
              <strong>Virtual Meetings:</strong> Use the built-in recording
              features of your preferred platform:
              <ul>
                <li>
                  <strong>Zoom:</strong> Start recording from the toolbar.
                </li>
                <li>
                  <strong>Google Meet:</strong> Use the “Record Meeting” option
                  under the three-dot menu.
                </li>
                <li>
                  <strong>Microsoft Teams:</strong> Use the “Start Recording”
                  feature in the toolbar.
                </li>
              </ul>
            </li>
            <li>
              <strong>In-Person Meetings:</strong> Use the built-in voice
              recorder on your Apple or Android phone.
            </li>
          </ul>
          <p>
            Once we are back online, you can upload your meeting recordings
            directly into Pulse360 for processing.
          </p>
        </div>

        <footer>© 2024 Pulse360. All rights reserved.</footer>
      </div>
    </Container>
  )
}

export default Maintenance
