import { SmartField } from 'components/drawers/Smartfields/types'
import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import { useEffect, useState } from 'react'
import {
  ConnectionConfiguration,
  getConnectionConfig,
} from 'stores/connections/connection.config'
import { smartfieldFactory as factory } from 'stores/smartfields/factory'
import { updateConfigAttr } from 'stores/smartfields/utils'
import { SmartFieldAtomAttrs } from '../../component'

export const useSmartFieldAtom = (atom: SmartFieldAtomAttrs) => {
  const [connection, setConnection] = useState<ConnectionConfiguration>()
  const [smartfield, setSmartfield] = useState<SmartField>()

  useEffect(() => {
    const getSmartfield = async () => {
      const sm = await factory.getSmartfield(atom)
      setSmartfield(sm)
    }

    if (atom) getSmartfield()
  }, [atom.label, atom.reuse, atom.disableAtomSync, atom])

  // TODO: need to do this?
  useEffect(() => {
    if (smartfield?.reuse) {
      updateConfigAttr(smartfield)
    }
  }, [smartfield])

  useEffect(() => {
    if (smartfield?.config.type === 'integration') {
      const config =
        smartfield.config as SmartFieldConfig<SmartFieldIntegrationConfig>

      return setConnection(getConnectionConfig(config.provider))
    }

    setConnection(undefined)
  }, [smartfield])

  return {
    connection,
    smartfield,
  }
}
