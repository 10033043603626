import moment from 'moment'

export const end = (mmt = moment(), unit = 'day') => mmt.endOf(unit)
export const start = (mmt = moment(), unit = 'day') => mmt.startOf(unit)

export const all = () => ({
  start: moment().subtract(20, 'years'),
  end: end(moment(), 'year'),
})

export const ytd = () => ({
  start: start(moment(), 'year'),
  end: end(moment(), 'year'),
})

export const q1 = () => ({
  start: start(moment().quarter(1), 'quarter'),
  end: end(moment().quarter(1), 'quarter'),
})

export const q2 = () => ({
  start: start(moment().quarter(2), 'quarter'),
  end: end(moment().quarter(2), 'quarter'),
})

export const q3 = () => ({
  start: start(moment().quarter(3), 'quarter'),
  end: end(moment().quarter(3), 'quarter'),
})

export const q4 = () => ({
  start: start(moment().quarter(4), 'quarter'),
  end: end(moment().quarter(4), 'quarter'),
})

export const lastYear = () => ({
  start: start(moment().subtract(1, 'year'), 'year'),
  end: end(moment().subtract(1, 'year'), 'year'),
})

export const lastQuarter = () => {
  const current = moment().quarter()
  if (current === 1) {
    return {
      start: start(moment().subtract(1, 'year').quarter(4), 'quarter'),
      end: end(moment().subtract(1, 'year').quarter(4), 'quarter'),
    }
  }
  const q = current - 1
  return {
    start: start(moment().quarter(q), 'quarter'),
    end: end(moment().quarter(q), 'quarter'),
  }
}

export const days = (days) => ({
  start: start(moment().subtract(days, 'days')),
  end: end(),
})

export const today = () => ({
  start: start(moment().startOf('day')),
  end: end(),
})

export const earliest = (dates) => {
  if (!dates.length) {
    return []
  }
  return dates.reduce((pre, cur) => {
    return Date.parse(pre) > Date.parse(cur) ? cur : pre
  })
}

export const latest = (dates) => {
  if (!dates.length) {
    return []
  }
  return dates.reduce((pre, cur) => {
    return Date.parse(pre) < Date.parse(cur) ? cur : pre
  })
}

export const since = (sinceDate) => {
  const now = moment()
  const inputDate = moment(sinceDate)

  if (!inputDate.isValid()) {
    throw new Error(`Invalid date format ${sinceDate}`)
  }

  const years = now.diff(inputDate, 'years')
  const months = now.diff(inputDate.add(years, 'years'), 'months')
  const days = now.diff(inputDate.add(months, 'months'), 'days')

  if (years > 0) {
    return years === 1 ? `${years} year` : `${years} years`
  } else if (months > 0) {
    return months === 1 ? `${months} month` : `${months} months`
  } else if (days > 0) {
    return days === 1 ? `${days} day` : `${days} days`
  } else {
    return 'Today'
  }
}
