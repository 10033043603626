import { Grid } from 'antd'
import { useCatchResizeObserverError } from 'components/hooks/useResizeHandler'
import MainLayout from 'components/layout/MainLayout.component'
import { CenterLayout } from 'components/layouts/center'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useSmartfieldFactory } from 'stores/smartfields/factory.hooks'
import MainTemplate from '../layout/templates/Main.template'
import { Lazy } from './async'
import Maintenance from './maintenance'

const { useBreakpoint } = Grid

export const AppRoutes = observer(() => {
  useCatchResizeObserverError()
  useSmartfieldFactory()

  const Tasks = useMemo(() => Lazy(() => import('./tasks')), [])
  const Help = useMemo(() => Lazy(() => import('./help')), [])
  const Profile = useMemo(() => Lazy(() => import('./profile')), [])
  const Admin = useMemo(() => Lazy(() => import('./admin')), [])
  const Settings = useMemo(() => Lazy(() => import('./settings')), [])
  const Reports = useMemo(() => Lazy(() => import('./reports')), [])
  const Templates = useMemo(() => Lazy(() => import('./templates')), [])
  const Onboard = useMemo(() => Lazy(() => import('./onboard')), [])
  const OAuth = useMemo(() => Lazy(() => import('./oauth2')), [])
  const Vault = useMemo(() => Lazy(() => import('./vault')), [])
  const QuickNote = useMemo(() => Lazy(() => import('./quick/note')), [])
  const Contacts = useMemo(() => Lazy(() => import('./contacts')), [])
  const Documents = useMemo(() => Lazy(() => import('./documents')), [])
  const Library = useMemo(() => Lazy(() => import('./library/page')), [])
  const Customize = useMemo(() => Lazy(() => import('./customize')), [])
  const Mobile = useMemo(() => Lazy(() => import('./mobile')), [])
  const CaptureGenius = useMemo(
    () => Lazy(() => import('./captureGenius/CaptureGeniusPage')),
    [],
  )

  const { sm = false, xs = false } = useBreakpoint()
  const location = useLocation()
  const { pathname } = location

  if (xs && !sm && !pathname.startsWith('/mobile')) {
    return <Navigate to={'mobile'} replace />
  }

  return (
    <MainLayout>
      <MainTemplate>
        <Routes>
          <Route path="notes/*" element={<Tasks />} />
          <Route path="documents/*" element={<Documents />} />
          <Route path="library/*" element={<Library />} />
          <Route path="contacts/*" element={<Contacts />} />
          <Route path="capture-genius/*" element={<CaptureGenius />} />
          <Route path="help/*" element={<Help />} />
          <Route path="profile/*" element={<Profile />} />
          <Route path="quick/note" element={<QuickNote />} />
          {global.perms.get('ui.route.360view') && (
            <Route path="/reports" element={<Reports />} />
          )}
          <Route path="settings/*" element={<Settings />} />
          <Route path="onboard/*" element={<Onboard />} />

          <Route path="templates/*" element={<Templates />} />
          <Route path="customize/*" element={<Customize />} />
          <Route path="vault" element={<Vault />} />
          <Route path="oauth2/callback" element={<OAuth />} />
          <Route path="mobile/*" element={<Mobile />} />

          {global.data.advisors.isAdmin && (
            <Route path="admin/*" element={<Admin />} />
          )}
          <Route element={<Navigate to={'contacts'} replace />} path="/" />
        </Routes>
      </MainTemplate>
    </MainLayout>
  )
})
