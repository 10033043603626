import { since } from 'helpers/dateRange'
import moment, { isMoment } from 'moment'
import { SmartField } from '../types'
import { SmartFieldConfig, SmartFieldDateConfig } from '../types/data'

export const dateFormatter = (smartfield: SmartField, value: unknown) => {
  const config = smartfield.config as SmartFieldConfig<SmartFieldDateConfig>
  const { date } = config

  try {
    if (isMoment(value)) {
      if (date) {
        const momentValue = value as moment.Moment

        value =
          date === 'since'
            ? since(momentValue.toDate())
            : momentValue.format(date)
      }
    } else if (typeof value === 'string' && moment(value).isValid()) {
      value = date === 'since' ? since(value) : moment(value).format(date)
    }
  } catch (err) {
    console.log('Unable to format as date!')
  }

  return value
}
